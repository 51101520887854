.available {
  font-family: 'Work Sans', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.mini-chart-bg {
  height: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #f8faff;
}

.mini-available-bar {
  display: flex;
  height: 100%;
  min-width: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
}
