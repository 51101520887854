@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap');

.footer {
  display: flex;
  color: black;
  font-size: 14px;
  line-height: 1.3em;
  padding: 30px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  font-family: 'Work Sans';
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.links > * {
  margin-right: 7vw;
}

.links-section {
  text-align: left;
}

.links-section > * {
  margin-bottom: 20px;
  white-space: nowrap;
}

.links-section a {
  margin-bottom: 20px;
  white-space: nowrap;
}

.links-section > p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.links-section > h1 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.socials {
  display: flex;
  flex-direction: column;
}

.socials > img {
  width: 130px;
}

.socials > * {
  margin-bottom: 20px;
}

.social-section {
  display: flex;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.social-section svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.info {
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.legal {
  display: flex;
}

.legal > * {
  margin-left: 10px;
  text-decoration: underline;
}

.legal > *:hover {
  cursor: pointer;
}

@media (max-width: 450px) {
  .legal {
    display: none;
  }

  .links-section {
    display: none;
  }
}

@media (max-width: 800px) {
  .links > * {
  }

  .links {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
