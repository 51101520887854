@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}

/* MAIN CONTAINER */
.PD-main {
  font-family: 'Work Sans';
  background-color: white;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}
/* HEADER CONTAINER */
.PD-header {
  margin: auto;
  font-size: 18px;
  line-height: 1em;
  font-weight: 600;
}
/* PROGRESS BAR */
.PD-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.PD-progress-full {
  width: 450px;
  height: 54px;
  position: relative;
  border-radius: 50px;
  border: solid 3px rgba(0, 0, 0, 0.2);
  background-color: #f8faff;
  display: flex;
  overflow: hidden;
}
.PD-progress-available-bar {
  /* position: absolute; */
  left: 0px;
  background-image: linear-gradient(90deg, #7112ff, #005eff);
}
.PD-progress-claimed-bar {
  /* position: absolute; */
  left: 0px;
  background-image: linear-gradient(90deg, #ff7112, #e6a400);
}
/* RELEASED CONTAINER */
.PD-released-container {
  margin-top: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PD-released-totalAvailable {
  text-align: center;
  margin-bottom: 5px;
  font-size: 50px;
  line-height: 1em;
  font-weight: 700;
  font-weight: bold;
}
.PD-released-totalAmount {
  text-align: center;
  font-size: small;
}
/* AVAILABLE - CLAIMED */
.PD-available-claimed-container {
  padding: 15px 0px;
  display: grid;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
.PD-available {
  width: 180px;
  text-align: center;
  padding: 5px 10px;
  border: solid 2px #61b2eb;
  border-radius: 50px;
}
.PD-claimed {
  width: 180px;
  text-align: center;
  padding: 5px 10px;
  border: solid 2px #ffca98;
  border-radius: 50px;
}
/* TIME REMAINING */
.PD-timeRemaining {
  text-align: center;
  padding: 20px 0px;
}
/* CLAIM BUTTON */
.PD-claimBttn-container {
  position: relative;
  z-index: 1;
  display: flex;
  height: 40px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-image: linear-gradient(45deg, #ff7112, #e6a400);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
}

.PD-claimBttn-container:hover {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.22), 0 14px 28px 0 rgba(0, 0, 0, 0.25);
}

.PD-claimBttn-container:active {
  box-shadow: none;
}

.claim-currency {
  display: flex;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.available {
  font-family: 'Work Sans', sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.mini-chart-bg {
  height: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #f8faff;
}

.mini-available-bar {
  display: flex;
  height: 100%;
  min-width: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
}

.header {
  width: 97vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  position: relative;
  padding: 20px;
}

.header > svg {
  height: 40px;
  margin: auto;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > svg:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.header > svg:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: black;
  color: white;
}

.wallet_button:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(113, 18, 255, 0.1);
  color: #7112ff;
}

.profile_button:active {
  background-color: rgba(113, 18, 255, 0.2);
}

.profile_button > p {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
}

.profile-image {
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 100px;
  background-color: #7112ff;
  background-image: linear-gradient(45deg, #7500ff, #9a00ff);
  margin-left: -3px;
}

.footer {
  display: flex;
  color: black;
  font-size: 14px;
  line-height: 1.3em;
  padding: 30px;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  font-family: 'Work Sans';
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.links > * {
  margin-right: 7vw;
}

.links-section {
  text-align: left;
}

.links-section > * {
  margin-bottom: 20px;
  white-space: nowrap;
}

.links-section a {
  margin-bottom: 20px;
  white-space: nowrap;
}

.links-section > p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.links-section > h1 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.socials {
  display: flex;
  flex-direction: column;
}

.socials > img {
  width: 130px;
}

.socials > * {
  margin-bottom: 20px;
}

.social-section {
  display: flex;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.social-section svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.info {
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.legal {
  display: flex;
}

.legal > * {
  margin-left: 10px;
  text-decoration: underline;
}

.legal > *:hover {
  cursor: pointer;
}

@media (max-width: 450px) {
  .legal {
    display: none;
  }

  .links-section {
    display: none;
  }
}

@media (max-width: 800px) {
  .links > * {
  }

  .links {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

