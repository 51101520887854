.header {
  width: 97vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  position: relative;
  padding: 20px;
}

.header > svg {
  height: 40px;
  margin: auto;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > svg:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > svg:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: black;
  color: white;
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(113, 18, 255, 0.1);
  color: #7112ff;
}

.profile_button:active {
  background-color: rgba(113, 18, 255, 0.2);
}

.profile_button > p {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
}

.profile-image {
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 100px;
  background-color: #7112ff;
  background-image: linear-gradient(45deg, #7500ff, #9a00ff);
  margin-left: -3px;
}
